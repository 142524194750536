<template>
  <div class="announcementDetails">
    <Header title="公告详情" theme="light" />
    <div class="zone__main">
      <div class="wrap__content">
        <div class="title">{{ noticeData[cId].title }}</div>
        <div class="content">{{ noticeData[cId].content }}</div>
        <div class="time">{{ noticeData[cId].updatedAt }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import { mapActions,mapState } from "vuex";
export default {
  name: "announcementDetails",
  components: {
    Header,
  },
  props:{
  },
  data() {
    return {
      cId:this.$route.params.cId
    };
  },
  computed:{
    ...mapState("notice", ["noticeData"]),
  },
  created() {
    this.getNotice()
  },
  methods: {
    ...mapActions("notice", ["getNotice"]),
  },
};
</script>
<style lang="scss" scoped>
.announcementDetails {
  width: 100%;
  height: calc(100vh - 52px);
  overflow-y: scroll;
  .zone__main {
    width: 100%;
    .wrap__content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
            @media (min-width:480px){
        justify-content: center;
      }
      div {
        width: 100%;
        &.title {
          font-size: 1.2rem;
          margin-bottom: 0.5rem;
          text-align: center;
        }
        &.content {
          font-size: 1.0rem;
          text-align: left;
          margin-bottom: 0.5rem;
          word-wrap:break-word;
        }
        &.time {
          color: var(--gray);
          font-size: 0.9rem;
          text-align: right;
        }
      }
    }
  }
}
</style>
